:root {
  --button-color: #5855d6;
  --sidebar-color: #333269;
  --heading: #333269;
  --dark-background: #1d8bcb;
  --darker-background: #166b9c;
  --chart-color: #5855d6;
  --success: #00b24b;
}

$button-color: var(--button-color);
$dark-background: var(--dark-background);
$darker-background: var(--darker-background);
$chart-color: var(--chart-color);
$success: var(--success);
$heading: var(--heading);
$sidebar-color: var(--sidebar-color);

.form-group {
  margin-bottom: 0.5rem;
}

.form-control {
  border-radius: 0.25rem;
}

.float-right {
  float: right !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiSnackbar-anchorOriginBottomCenter {
  width: 30%;
  .MuiPaper-root {
    // background-color: $dark-background !important;
    font-weight: 600 !important;
    font-family: "Roboto" !important;
    justify-content: center;
    border-radius: 0px !important;
  }
  .MuiSnackbarContent-message {
    font-size: 15px;
    color: #fff;
  }
  .mdc-snackbar {
    z-index: 9999 !important;
    text-align: center;
  }
}

.elipsis-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.elipsis-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elipsis-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.layout {
  position: fixed;
  opacity: 0.8;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #f5f5f5;
  display: none;
  cursor: not-allowed;
  overflow: hidden;
}

.elite-content-holder {
  width: 45%;
  .modal-header {
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    color: white;
    font-size: 20px;
    font-family: Roboto;
  }
  .main-modal-header {
    padding: 10px 12px;
    background: #332e59;
    h3 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      width: 95%;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
  }
  .like-btn {
    display: flex;
    list-style: none;
    // margin-top: 30px;
    margin-left: -5px;
    margin-top: 10px;
    .liked-active {
      color: #8b7df2 !important;
    }
    ul {
      list-style: none;
    }
    li {
      float: none;
      // border: 1px solid #eee;
      padding: 5px 7px;
      // border-radius: 33px;
      // margin-left: 5px;
      // margin-right: 8px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      color: #9d99bf;
      img {
        vertical-align: middle;
      }
    }
  }
  button {
    background-color: $button-color;
    border: none;
    color: #fff;
    border-radius: 4px;
    height: 38px;
    font-family: Roboto;
    margin-top: 15px;
    width: 150px;
  }
  .sticky-close {
    position: absolute;
    right: 15px;
    top: 18px;
    cursor: pointer;
    img {
      width: 18px;
    }
  }
}
.content-modal-width {
  width: 35%;
}

.save-btn {
  button {
    background-color: $button-color;
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    line-height: 18px;
    width: 136px;
  }
}

h2 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.w-96 {
  width: 96% !important;
}
//popover history
.bs-popover-bottom {
  transform: translate(1048px, 570px) !important;
  .popover-arrow {
    display: none !important;
  }
}
.popover-history {
  padding: 5px 10px;
  font-family: Roboto;
  max-height: 200px;
  overflow: auto;

  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
  .close-details {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    img {
      width: 14px;
    }
  }
  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #484750;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0px;
    color: #696873;
  }
  .history {
    list-style: none;
    padding: 5px;
    li {
      font-family: Roboto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dddde2;
      padding: 5px;
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
        text-align: left;
      }
    }
  }
}

//confirm delete modal
.confirm-modal {
  width: 490px;
  padding: 20px;
  h1 {
    // text-align: left;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    //   color: $heading-color;
    a {
      text-decoration: underline !important;
    }
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #535259;
    margin-top: 10px;
  }
  .save-btn {
    margin-top: 10px;
    font-family: Roboto;
    button {
      border: none;
      background-color: #e8325d !important;
      width: 144px;
      height: 40px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      border-radius: 4px;
      &:nth-child(2) {
        background-color: #fff !important;
        border: 1px solid #9d99bf;
        color: #222222;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.explanation-popup {
  position: relative;
  width: 295px;
  border-radius: 12px;
  pointer-events: auto;
  z-index: 3;
  border: none;
  position: absolute;
  top: 10px;
  right: -230px;
  padding: 10px;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #555555;
  margin-left: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: -15px;
    border-width: 15px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  h1 {
    text-align: left;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 18px;
    color: #555555;
    padding-left: 5px;
  }
  small {
    margin-left: -5px;
  }
  .close-explaination {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .mobile-drawer .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    height: 20vh !important;
    padding: 5px;
    .MuiBackdrop-root {
      background-color: transparent;
    }
    .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
      opacity: 0 !important;
      transition: unset;
    }
    h1 {
      text-align: left;
      font-weight: bold;
      font-size: 14px !important;
      line-height: 18px;
      color: #555555;
    }
    .close-explaination {
      position: absolute;
      right: 20px;
      top: 18px;
      cursor: pointer;
    }
  }
  .MuiSnackbar-anchorOriginBottomCenter {
    width: 100%;
  }
}

//View resource
.resource-details {
  margin-right: 0;
  margin-top: 0 !important;
  margin-bottom: 0;
  width: calc(100vw - 291px);
  min-height: calc(100% - 0rem);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 7;
  background: #fff;
  &::before {
    background: rgba(51, 51, 51, 0.68);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    content: "";
    width: calc(100vw - 0px);
  }

  .modal-content {
    height: 100%;
    border: 0;
  }

  .modal-body {
    padding: 0;
    background: #f6f6f6;
    height: 100%;

    .notes-details {
      width: 100%;
      background: #fff;
      height: 100%;
      padding: 25px 50px;
      overflow: auto;
      scrollbar-width: thin;
      & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
      }
      .playlist-header {
        position: relative;
        h1 {
          small {
            font-size: 20px;
            font-weight: 600;
            line-height: 22px;
            font-family: Source Sans Pro;
          }
        }
        p {
          color: gray;
          font-size: 13px;
        }
        .sticky-cross-holder {
          position: absolute;
          right: -20px;
          display: inline-block;
          font-size: 1rem;
          line-height: 1.5;
          vertical-align: middle;
          top: 2px;
          cursor: pointer;
          img {
            width: 18px;
          }
        }
      }
      .view-btn {
        background-color: $button-color !important;
        color: #fff;
        border: none;
        width: 220px;
        height: 40px;
        border-radius: 4px;
      }
      .quiz-holder {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        h1 {
          font-size: 20px;
        }
        .ques-title {
          font-family: Source Sans Pro;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0px;
          text-align: center;
          color: #555555;
          p {
            min-height: 30px;
            span {
              line-height: 24px;
            }
          }
        }

        ul {
          list-style: none;
          margin-top: 10px;
          width: 80%;
          li {
            // display: flex;
            justify-content: center;
            align-items: center;
            .option {
              border: 1px solid #ddd;
              width: 70%;
              min-height: 35px;
              margin-top: 10px;
              box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
              padding: 5px;
              margin-left: 17px;
              cursor: pointer;
              margin-right: -10px;
              color: #555555;
              p {
                color: #555555;
                span {
                  color: #555555;
                }
                img {
                  vertical-align: middle;
                }
              }
            }

            .option1 {
              border: 1px solid #ddd;
              width: 70%;
              min-height: 35px;
              margin-top: 10px;
              box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
              padding: 5px;
              margin-left: 29px;
              cursor: pointer;
              color: #555555;
              p {
                color: #555555;
                span {
                  color: #555555;
                }
                img {
                  vertical-align: middle;
                  &:hover {
                    -ms-transform: scale(4); /* IE 9 */
                    -webkit-transform: scale(4); /* Safari 3-8 */
                    transform: scale(4);
                    position: relative;
                    /* right: -380px; */
                    z-index: 9999;
                  }
                }
              }
            }
          }
        }
      }
      .description {
        margin-left: -27px;
        background: #fff;
        padding: 15px;
        border-radius: 6px;
        // height: calc(100vh - 275px);
        .row {
          justify-content: flex-start;
          align-items: flex-start;
        }
        .articlediv {
          min-height: 160px;
          overflow: auto;
          .content-middle {
            border-radius: 6px;
            height: calc(100vh - 50px);
            width: 100%;
            overflow-y: auto;
            position: relative;
            margin-top: 15px;
          }
        }
        .embed-responsive-item {
          width: 80%;
          height: 320px;
        }
      }
      .like-btn {
        display: flex;
        list-style: none;
        margin-top: 30px;
        .liked-active {
          color: $chart-color !important;
        }
        li {
          float: none;
          border: 1px solid #eee;
          padding: 5px 7px;
          border-radius: 33px;
          margin-left: 5px;
          margin-right: 8px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 500;
          img {
            vertical-align: middle;
          }
        }
      }

      .save-notes {
        width: 50%;
        textarea {
          resize: both;
        }
        .addWidth {
          // margin-top: -45px;
          margin-left: -2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .removeWidth {
          margin-top: 0px;
          margin-left: 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        p {
          //styleName: Action Text;
          font-family: Source Sans Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: left;
          color: $button-color;
        }
        span {
          //styleName: Body Text;
          font-family: Source Sans Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0px;
          color: #9b9aa3;
        }
      }
      .videodiv {
        .sticky-cross-holder {
          position: absolute;
          right: -20px;
          display: inline-block;
          font-size: 1rem;
          line-height: 1.5;
          vertical-align: middle;
          top: 1px;
          cursor: pointer;
          img {
            width: 18px;
          }
        }
        .content-details {
          position: relative;
        }

        .content-middle1 {
          background: #fff;
          padding: 0px;
          border-radius: 6px;
          height: calc(100vh - 100px);
          // overflow-y: auto;
          .elipsis {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .question {
            padding-left: 23px;
            text-align: left;
          }
          h6 {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            font-weight: 400;
            color: #d4d4d4;
          }

          p {
            margin-bottom: 20px;
            line-height: 24px;
          }
        }
      }
    }
    .react-pdf__Page__textContent {
      display: none;
    }
    .content-middle1 {
      background: #fff;
      padding: 15px;
      border-radius: 6px;
      height: calc(100vh - 50px);
      width: 85%;
      overflow-y: auto;
      position: relative;
      border: 1px solid #cccccc;
      margin-top: 15px;
      & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
      }
      .tools {
        position: absolute;
        top: 10px;
        button {
          border: none;
          background-color: transparent;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          img {
            width: 20px;
          }
          &:hover {
            background-color: #f6f6f6;
            border-radius: 50%;
          }
        }
      }
      .react-pdf__Document {
        position: relative;
        .zoom-btn {
          position: absolute;
          // right: 10px;
          top: -10px;
          z-index: 1;
          button {
            border: none;
            background-color: transparent;
            width: 45px;
            height: 45px;
            img {
              width: 20px;
            }
            &:hover {
              background-color: #f6f6f6;
              border-radius: 50%;
            }
          }
        }
        .react-pdf__Page {
          scale: unset;
          span {
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
          }
        }
        .react-pdf__Page__annotations {
          display: none !important;
        }
      }
    }
    .nextPage {
      border: none;
      background-color: #6457e8;
      width: 25px;
      height: 25px;
      padding-bottom: 3px;
      border-radius: 50%;
      margin-top: 8px;
    }
  }
}

@media (max-width: 767px) {
  .resource-details {
    top: 67px;
    max-width: 100%;
    width: 100%;
    z-index: 5;
    margin-top: 0;
    padding-bottom: 0;
    .modal-body {
      .padd {
        padding: 15px 30px !important;
      }

      .notes-details {
        padding: 15px;
        overflow-x: hidden;
        .playlist-header {
          .topic-back {
            width: 80%;
          }
          .sticky-cross-holder {
            width: 35px;
            top: -5px !important;
            right: -12px !important;
            height: 35px;
            background: #fff;
            border: 1px solid #f5821f;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            img {
              width: 14px;
            }
          }
        }
        .description {
          padding-right: 0px;
          padding-left: 12px;
          margin-right: -15px;

          .embed-responsive-item {
            width: 100%;
            height: 315px;
          }
          .activeFeed-image {
            margin-top: 20px;
            display: flex;
            justify-self: center;
            padding-right: 0px;
            img {
              width: 96% !important;
            }
          }
        }
        .save-notes {
          width: 100%;
          margin-bottom: 125px;
        }
        .videodiv {
          .sticky-cross-holder {
            width: 35px;
            height: 35px;
            background: #fff;
            border: 1px solid #f5821f;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            img {
              width: 14px;
            }
            z-index: 2;
            top: -9px;
            right: -10px;
          }
        }
      }
      .react-pdf__Document {
        overflow: auto;
        .react-pdf__Page {
          .react-pdf__Page__canvas {
            display: block;
            user-select: none;
            // width: 100% !important;
            height: 840px;
            // margin-left: -50px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .videodiv .content-middle1 {
    width: 55%;
    margin: 0 auto;
  }
}
.cursor-pointer {
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .resource-details {
    max-width: 100%;
    width: 100%;

    .header-div {
      padding: 10px 15px !important;

      .left {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .right-icon {
        button {
          padding: 0.375rem 0.5rem;
        }
      }
    }
  }
}
