@import "../../../App.scss";

.report-holder {
    h2 {
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .report-tabs {
        display: flex;
        align-items: center;
        .report-tab {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
            color: $heading;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18%;
            height: 40px;
            padding: 12px;
            cursor: pointer;
        }
        .active {
            border-bottom: 2px solid #3f51b5;
        }
    }
    .report-filters {
        margin-top: 20px;
        display: flex;
        align-items: center;
        select {
            margin-left: 10px;
            width: 17%;
            border: 1px solid #5855d6;
            color: #3f51b5;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            background-image: url("../../../assets/arrow-down1.svg");
            background-position: 95% center;
        }
        .form-control {
            padding: 0rem 0.5rem;
        }
    }
    .school-reports {
        width: 95%;
        .center-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
            }
            select {
                width: 20%;
            }
            .legands {
                // width: 20%;

                .legand-1 {
                    display: flex;
                    align-items: center;
                    div {
                        width: 24px;
                        height: 24px;
                        background-color: #3f51b5;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                    }
                }
            }

            .highcharts-data-label-color-0,
            .highcharts-data-label-color-1 {
                text {
                    fill: #696873 !important;
                    font-family: Lato;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
            .highcharts-xaxis-labels {
                text {
                    fill: #696873 !important;
                    font-family: Roboto;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
        }
        .program-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            .highcharts-title {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold !important;
                font-size: 18px !important;
                line-height: 24px;
                color: $heading !important;
                fill: $heading !important;
            }
            .highcharts-subtitle {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal !important;
                font-size: 14px !important;
                line-height: 24px;
                color: $heading !important;
                fill: $heading !important;
            }
            .highcharts-data-label {
                text {
                    fill: #696873 !important;
                    font-family: Roboto !important;
                    font-size: 14px !important;
                    color: #696873 !important;
                    font-style: normal;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .highcharts-xaxis-labels text {
                font-family: Roboto !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px !important;
                line-height: 18px;
                color: #696873 !important;
                fill: #696873 !important;
            }
            .program-legand {
                width: 100%;
                display: flex;
                // justify-content:center;
                align-items: center;

                .legand {
                    height: 20px;
                    width: 20%;
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    width: 80%;
                }
            }
        }
        .reports-header {
            background-color: #fff;
            margin-top: 10px;
            padding: 20px;
            position: relative;
            .content-1 {
                background-color: #fff;
                width: 180px;
                height: 80px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                padding: 16px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 15px;
                img {
                    width: 56px;
                    height: 56px;
                }
                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #484750;
                    text-align: center;
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        color: #696873;
                    }
                }
            }
            .header-width {
                width: 68%;
                .css-2b097c-container {
                    .css-1okebmr-indicatorSeparator {
                        display: none;
                    }
                    .css-1pahdxg-control {
                        box-shadow: 0px 0px 0px 1px $chart-color !important;
                    }
                    input:focus {
                        box-shadow: none !important;
                    }
                }
            }
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
            }
        }
        .center-table {
            margin-top: 12px;
            background-color: #fff;

            .table td {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 2px solid #dee2e6;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                &:first-child {
                    color: $button-color;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
            .table thead th {
                vertical-align: bottom;
                text-align: center;
                border-bottom: 0px solid #dee2e6;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $heading;
                border-top: 0px solid #dee2e6;
                background: #c0beff33;
                border-bottom: 0;
                border-top: none;
                padding: 10px;
            }
        }
        .student-graph {
            background: #fff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            padding: 35px;
            width: 97.5%;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    color: #696873;
                }
                small {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    float: right;
                    cursor: pointer;
                    img {
                        vertical-align: middle;
                    }
                }
            }
            .legend-box {
                display: flex;
                margin-top: 15px;
                .legend {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    margin-right: 12px;
                    .legend-circle {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                    }
                }
            }
            .section-rank {
                width: 50%;

                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 128px;
                    line-height: 154px;
                    color: $heading;
                }
            }
            .program-rank {
                width: 50%;
                .chart-cards {
                    flex: 1;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    height: 103px;
                    text-align: center;
                    // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    position: relative;
                    display: flex;
                    z-index: 1;
                    .current-subjects {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #f7fafa;
                        padding: 20px;
                        p {
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            color: #696873;
                            text-align: center;
                        }
                    }
                    h4 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 48px;
                        background-color: $chart-color;
                        color: #fff;
                        font-family: Lato;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
                        border-radius: 4px;
                    }

                    h5 {
                        margin-top: 10px;
                        font-size: 39px;
                        font-weight: 700;
                        font-family: "Roboto", sans-serif;
                        text-align: center;

                        img {
                            float: right;
                            margin-top: -3px;
                        }
                    }
                }
            }
            .subject-chart {
                .highcharts-axis-line {
                    display: none !important;
                }
            }
        }

        .iput {
            width: 3.5%;
            border-radius: 4px;
            border: 1px solid #9b9aa3;
            padding: 0.25rem 0.3rem;
            text-align: center;
        }
        .user-pagination {
            display: flex;
            align-items: center;
            margin-top: 15px;
            font-size: 14px;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
            div {
                display: flex;
                align-items: center;
                width: 110px;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;

                input {
                    width: 30%;
                    border-radius: 4px;
                    border: 1px solid #9b9aa3;
                    padding: 0.25rem 0.3rem;
                    text-align: center;
                }
            }
            .go-btn {
                border: none;
                color: $button-color;
                font-weight: 600;
                background-color: transparent;
                border-radius: 4px;
                padding: 4px;
            }
        }
    }
}

@media (max-width: 767px) {
    .report-holder {
        padding-left: 10px;
        margin-bottom: 50px;
        .report-tabs {
            .report-tab {
                width: 30%;
                margin-right: 10px;
            }
        }
        .report-filters {
            select {
                width: 50%;
            }
        }
        .center-table {
            .table-admin {
                width: 100%;
                min-height: 345px;
                overflow: scroll;
                table tr th:first-child {
                    z-index: 3 !important;
                }
            }
            table th,
            table td {
                white-space: nowrap;
                padding: 10px 20px;
            }
            table tr th:first-child,
            table td:first-child {
                position: sticky;
                width: 100px;
                min-width: 130px;
                max-width: 130px;
                left: -1px;
                z-index: 3;
                background: #fff;
                overflow: hidden;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
            }

            table tr th {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #fff;
            }
        }
    }
}
